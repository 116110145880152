// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-refund-policy-js": () => import("./../src/pages/en/refund-policy.js" /* webpackChunkName: "component---src-pages-en-refund-policy-js" */),
  "component---src-pages-en-terms-of-service-js": () => import("./../src/pages/en/terms-of-service.js" /* webpackChunkName: "component---src-pages-en-terms-of-service-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ru-js": () => import("./../src/pages/ru.js" /* webpackChunkName: "component---src-pages-ru-js" */),
  "component---src-pages-ru-privacy-policy-js": () => import("./../src/pages/ru/privacy-policy.js" /* webpackChunkName: "component---src-pages-ru-privacy-policy-js" */),
  "component---src-pages-ru-refund-policy-js": () => import("./../src/pages/ru/refund-policy.js" /* webpackChunkName: "component---src-pages-ru-refund-policy-js" */),
  "component---src-pages-ru-terms-of-service-js": () => import("./../src/pages/ru/terms-of-service.js" /* webpackChunkName: "component---src-pages-ru-terms-of-service-js" */)
}

